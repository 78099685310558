import React from 'react';
import './About.css';

function About() {
  return (
    <div>
      <p>About page</p>
    </div>
  );
}

export default About;
