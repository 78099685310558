import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <div>
      <p>NotFound page</p>
    </div>
  );
}

export default NotFound;
