import React from 'react';
import './Home.css';

function Home() {
  return (
    <div>
      <p>Home page</p>
    </div>
  );
}

export default Home;
